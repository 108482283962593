import { Controller } from "@hotwired/stimulus"
import * as Credential from "../components/credential";


export default class extends Controller {
  static targets = ["loginField"]

  connect() {
    //console.log("new session")
    super.connect()

    if ($("#user_session .digits").length > 0) {
      $("#user_session .submit.button.disabled").removeClass("disabled")
    }

    $('#user_session .checkbox').checkbox()

    if ($("#user_session .otp.back").length > 0) {
      $("#user_session .otp").show()
      $("#user_session .otp input#digit-1").focus()
      $("#user_session .login").hide()

      $("#user_session .otp.back").on("click", function() {
        $("#otp-error").remove()
        $("#user_session .otp.back").hide()
        $("#user_session .otp").hide()
        $("#user_session .login").show()
        $('input.digit-field').val("")
        $('#otp-code-field').val("")
      })
    }

    if ($("#user_session .logged_in").length > 0) {
      post(`/user_sessions/redirect`).then(request=>{
        if(request?.response?.redirected){
          Turbo.visit(request.response.url)
        }
      })
    } 

    if ($("#webauthn").length > 0) {
      $("#user_session .submit.button").addClass("disabled")
      Credential.get($("#webauthn").data("challenge"));
    }
  }
}
