import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['mainField']
  static values = {
    codeLength: String
  }

  connect() {
    super.connect()

    this.element.querySelectorAll('input.digit-field').forEach((field, i) => {
      field.removeAttribute("disabled")
    })
    this.element.querySelector('input#digit-1').focus()
  }

  checkAllowance(e) {
    if (!this._isValidOtpField(e.key)) {
      e.preventDefault();
    }
  }

  handleInputEvent(e) {
    let digitValue = e.data

    if (digitValue == null) { 
      const first_input = this.element.querySelector(`input#digit-1`).value

      if (first_input != undefined && first_input != "") {
        digitValue = first_input
      } else {
        return;
      }
    }

    if (digitValue.length == Number(this.codeLengthValue) && !Number.isNaN(digitValue)) {
      for (var i = 1; i < (Number(this.codeLengthValue) + 1); i += 1) {
        this.element.querySelector(`input#digit-${i}`).value = digitValue[i-1]
      }
      this.element.querySelector(`input#digit-${this.codeLengthValue}`).focus()
    } else {
      if(('0' <= digitValue && digitValue <= '9') || ('a' <= digitValue && digitValue <= 'z')) {
        const next = this.element.querySelector(`input#${e.currentTarget.dataset.next}`)
  
        if(next !== null) {
          next.focus()
        }
      }
    }

    this._updateMainField()
  }

  handleKeyUp(e) {
    if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
      const prev = this.element.querySelector(`input#${e.currentTarget.dataset.previous}`)

      if(prev !== null) {
        prev.focus()
      }
    } else if (e.key === 'ArrowRight') {
      const next = this.element.querySelector(`input#${e.currentTarget.dataset.next}`)

      if(next !== null) {
        next.focus()
      }
    } else if (e.key === 'Enter' && this._allFieldsAreFilled()) {
      
      if (document.querySelector(".second.factor.modal")) {
        document.querySelector(".second.factor.modal .activate.button").click()
      } else {
        this.mainFieldTarget.form.submit()
      }
    }
  }

  _updateMainField() {
    let otpCode = ''
    for (var i = 1; i < (Number(this.codeLengthValue) + 1); i += 1) {
      otpCode += this.element.querySelector(`input#digit-${i}`).value
    }

    this.mainFieldTarget.value = otpCode
  }

  _isValidOtpField(key) {
    return (key === 'Backspace') ||
            (key === 'ArrowLeft') ||
            (key === 'ArrowRight') ||
            ('0' <= key && key <= '9') ||
            ('a' <= key && key <= 'z');
  }

  _allFieldsAreFilled() {
    return this.mainFieldTarget.value.length === Number(this.codeLengthValue)
  }

}
